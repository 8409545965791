import { SideNavInterface } from '../../shared/interfaces/side-nav.type';

export const MENU_CONFIG: SideNavInterface[] = [
    {
        path: '/modelo',
        title: 'Modelo',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    },
    // {
    //     path: '/',
    //     title: 'Principal',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'inbox',
    //     submenu: []
    // },
    {
        path: '/cabecalho',
        title: 'Cabeçalho',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'insert-row-above',
        submenu: []
    },
    {
        path: '/rodape',
        title: 'Rodapé',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'insert-row-below',
        submenu: []
    },
    {
        path: '/parametro',
        title: 'Configuração',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'setting',
        submenu: [
            {
                path: '/parametro',
                title: 'Parâmetro',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'tags',
                submenu: []
            },
            {
                path: '/tipo-parametro',
                title: 'Tipo de Parâmetro',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'tag',
                submenu: []
            },

        ]
    },
   
]    