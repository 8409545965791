import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { map, switchMap, take } from 'rxjs/operators';
import { CrudService } from 'src/app/core/services/abstract/crud.service';
import { PAGINACAO_PAGE_SIZE } from 'src/app/shared/constantes';
import { Page } from 'src/app/shared/interfaces/page.type';
import { IPagination } from 'src/app/shared/interfaces/pagination.type';
import { Modelo } from 'src/app/shared/models/modelo.model';
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: "root"
})
export class ModeloService extends CrudService<Modelo>{

  constructor(protected http: HttpClient) {
    super(http,"admin/modelo");
  }

  disponibilizarModeloParaTodasOrganizacoes(idModelo: number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/${idModelo}/disponibilizar/todas`, {})
    .pipe(take(1));
  }
  
  disponibilizarModelo(body): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/disponibilizar`, body)
    .pipe(take(1));
  }
  
  disponibilizarModeloEmLote(body): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/disponibilizar-lote`, body)
    .pipe(take(1));
  }

  deletarDisponibilidadeModelo(id): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/disponibilizar/${id}`)
    .pipe(take(1));
  }


  consultarDisponibilizacaoModeloOrganizacao(pageChange:Observable<IPagination>, idModelo:number): Observable<Page<any[]>> {
    return combineLatest([
        pageChange
        ])
    .pipe(
      map(([pageChange]) => {    
        let param = {};
            param['page'] = pageChange.pageIndex? `${pageChange.pageIndex-1}` : `${-1}`;
            param['size'] = pageChange.pageSize? `${pageChange.pageSize}` : `${PAGINACAO_PAGE_SIZE}`;
            return param;
          }),
      switchMap(param => {
        const params = new HttpParams({ fromObject: param });
        return this.http.get<Page<any[]>>(`${this.apiUrl}/${idModelo}/disponibilizacao`, { params });
      }));
  }

  gerarDocumento(idModelo, mapVariaveis?: Map<string, string>): Observable<any> {
    let variaveis = {};

    if(mapVariaveis){
      mapVariaveis.forEach((val: string, key: string) => {
        variaveis[key] = val;
      });
    }

    return this.http.post<any>(`${this.apiUrl}/${idModelo}/gerar-documento`, variaveis)
    .pipe(take(1));
  }

  listarVariaveisDasTAGsDoModelo(idModelo): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${idModelo}/variaveis`)
    .pipe(take(1));
  }

  downloadPreviaPDF(idModelo, conteudoHtml){
    return this.http.post<Blob>(`${this.apiUrl}/${idModelo}/download/pdf`, conteudoHtml, {responseType: 'blob' as 'json' })
    .pipe(take(1),
      map(res => new Blob([res], { type: "application/pdf" })),
      tap(res => saveAs(res, `documento.pdf`))
    );
  }

  gerarPDF(idModelo, conteudoHtml) : Observable<Blob> {
    return this.http.post<Blob>(`${this.apiUrl}/${idModelo}/download/pdf`, conteudoHtml, {responseType: 'blob' as 'json' })
    .pipe(
        take(1),
        map(res => new Blob([res], { type: "application/pdf" }))
    );
  }

  ativar(id): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}/${id}/ativar`,{})
    .pipe(take(1));
  }

  inativar(id): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}/${id}/inativar`,{})
    .pipe(take(1));
  }

}
