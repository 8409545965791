import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppNgZorroAntdModule } from './components/ng-zorro/ng-zorro-antd.module';
import { HasAnyAuthorityDirective } from "./directives/has-any-authority.directive";
import { SideNavDirective } from './directives/side-nav.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { IconFilterPipe } from './pipes/iconFilter.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { ModalInputVariaveisComponent } from './components/modal/modal-input-variaveis/modal-input-variaveis.component';
import { ModalModelDocumentComponent } from "./components/modal/modal-uploadprocesso/modal-uploadprocesso.component";

@NgModule({
    declarations: [
        SearchPipe,
        DateFormatPipe,
        IconFilterPipe,
        SideNavDirective,
        HasAnyAuthorityDirective,
        ModalInputVariaveisComponent,
        ModalModelDocumentComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        AppNgZorroAntdModule,
        SearchPipe,
        SideNavDirective,
        DateFormatPipe,
        IconFilterPipe,
        HasAnyAuthorityDirective,
        ModalInputVariaveisComponent,
        ModalModelDocumentComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        AppNgZorroAntdModule,
    ],
    providers: []
})

export class SharedModule { }
