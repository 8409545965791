import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ModeloService } from 'src/app/modules/admin/modelo/modelo.service';
import { Modelo } from 'src/app/shared/models/modelo.model';
import { VisualizadorService } from './visualizador.service';


@Component({
  selector: 'app-modal-uploadprocesso',
  templateUrl: './modal-uploadprocesso.component.html',
  styleUrls: ['./modal-uploadprocesso.component.css']
})
export class ModalModelDocumentComponent implements OnInit {

  @Input() showModal = {value: false};
  @Input() modelo: Modelo;
  @Input() numeroDocumento: string;
  @Input() conteudo: string;

  errorMessage: string;

  loading = false;

  loadingModelos = false;
  loadingGerandoDocumento = false;
  loadingTipoFase = false;

  form: FormGroup;

  
  constructor(private fb: FormBuilder,
              private modeloDocumentosService: ModeloService,
              private visualizadorService: VisualizadorService
              ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm(){
    this.form = this.fb.group({
      numerosDocumento: [''],
      tipoDocumento: [null],
      descricaoDocumento: ['', [ Validators.required ]],
      tipoFase: [''],
      descricaoFase: [''],
      assinarDocumento: false,
      assinatura: this.fb.group({
        usuario: [''],
        senha: ['']
      })
    });

    this.form.get('assinatura').disable();

    this.form.get('assinarDocumento').valueChanges
    .subscribe(isAssinado => { 
      if(isAssinado) {
        this.form.get('assinatura').enable();
        this.form.get('assinatura.usuario').setValidators(Validators.required);
        this.form.get('assinatura.senha').setValidators(Validators.required);
      } else {
        this.form.get('assinatura').clearValidators();
        this.form.get('assinatura').disable();
      }

      this.form.updateValueAndValidity();
    });
  }

  onModeloChanged(modelo){
    this.form.controls.tipoDocumento.setValue(modelo.tipoDocumento);
  }

  enviar() {
    this.loading = true;

    this.form.get('assinatura.usuario').markAsDirty();
    this.form.get('assinatura.senha').markAsDirty();
    this.form.get('assinatura').updateValueAndValidity();

    for(const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    if(this.form.valid) {
      this.modeloDocumentosService.gerarPDF(this.modelo.id, this.conteudo).subscribe(anexo => {
        this.enviarAnexo(anexo)
      });
    }
  }

  enviarAnexo(anexo: Blob) {
   
    this.loading = true;
    this.errorMessage = null;

    const formData = new FormData();
    formData.append('numerosDocumento', this.numeroDocumento);
    formData.append('tipoDocumento', this.modelo.tipoDocumento.toString());
    formData.append('tipoFase', this.form.get('tipoFase').value);
    formData.append('descricaoDocumento', this.form.get('descricaoDocumento').value);
    formData.append('descricaoFase', this.form.get('descricaoFase').value);

    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(anexo);

    formData.append('arquivo', new File(arrayOfBlob, "conteudo.pdf", { type: 'application/pdf' }));

    const assinarDocumento = this.form.get('assinarDocumento').value;
    
    if(assinarDocumento) {
      formData.append('assinatura.usuario', this.form.get('assinatura.usuario').value);
      formData.append('assinatura.senha', this.form.get('assinatura.senha').value);
    }

    this.visualizadorService.upload(formData)
    .pipe(
      catchError(err => {
        this.errorMessage = err.error.message;
        return throwError(null);
      }),
      finalize(() => this.loading = false)
    ).subscribe(() => {
      this.fecharModal();
      this.voltar();
    });
  }

  cancelar(){
    this.fecharModal();
  }

  fecharModal(){
    this.errorMessage = null;
    this.form.reset();
    this.showModal = {value: false};
  }

  voltar(){
    window.history.back();
  }
}