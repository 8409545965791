
import { Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import { take } from 'rxjs/operators';
import { UsuarioAutenticadoService } from 'src/app/core/services/usuario-autenticado.service';

/**
 * @howToUse
 * ```
 *     <some-element *hasAuthority="'ROLE_NAME1'">...</some-element>
 *
 *     <some-element *hasAuthority="['ROLE_NAME1', 'ROLE_NAME2']">...</some-element>
 * ```
 */
@Directive({
    selector: '[hasAuthority]'
})
export class HasAnyAuthorityDirective {
    private authorities: string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private usuarioAutenticadoService: UsuarioAutenticadoService
    ) {
    }

    @Input()
    set hasAuthority (value: string | string[]) {
        this.authorities = typeof value === 'string' ? [value] : value;
        this.updateView();
    }

    private updateView(): void {
        if(this.authorities.length == 0){
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }else {
            this.usuarioAutenticadoService.getUsuarioAutenticado().pipe(take(1)).subscribe(usuario => {
                for (let i = 0; i < this.authorities.length; i++) {
                        if(usuario.authorities.find(data => data.authority === this.authorities[i])){
                            this.viewContainerRef.createEmbeddedView(this.templateRef);
                            return;
                        }
                    }
            });
        }
    }
}

