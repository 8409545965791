<nz-modal [(nzVisible)]="showModal.value" nzTitle="Preencha as informações do documento" (nzOnCancel)="cancelar()"  [nzFooter]="modalFooter" nzWidth="800">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-12">
          <nz-form-item>
              <nz-form-label nzRequired nzFor="descricaoDocumento">Descrição do Documento</nz-form-label>
             
              <textarea nz-input id="descDoc" formControlName="descricaoDocumento" class="form-control" rows="3" maxlength="500"></textarea>

              <small>({{ form.get('descricaoDocumento')?.value?.length }} / 500)</small>

          </nz-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
          <nz-form-item>
              <label>
                  <input class="form-check-input" type="checkbox" formControlName="assinarDocumento"> 
                  Assinar Documento
              </label>
          </nz-form-item>
      </div>
    </div>
    <div class="row" formGroupName="assinatura" *ngIf="form.get('assinarDocumento').value">
      <div class="col-lg-6 col-md-12">
          <nz-form-label nzRequired nzFor="usuarioId">Usuário</nz-form-label>
          <input nz-input  id="usuarioId" type="text" class="form-control" formControlName="usuario" />
      </div>

      <div class="col-lg-6 col-md-12">
            <nz-form-label nzRequired nzFor="senhaId">Senha</nz-form-label>
            <input nz-input  id="senhaId" type="password" class="form-control" formControlName="senha" />
      </div>
    </div>
    <div class="row" >
      <div class="col-md-12">
        <div style="color: #f5222d;" *ngIf="errorMessage">
          <strong>Erro!</strong> {{ errorMessage }}
        </div>
      </div>
    </div>
    <ng-template #modalFooter>
      <button nz-button nzType="default" (click)="cancelar()">Sair</button>
      <button nz-button nzType="primary" (click)="enviar()" [nzLoading]="isConfirmLoading" [disabled]="form.invalid">Enviar</button>
    </ng-template>
  </form>
</nz-modal>