import { Routes } from '@angular/router';


export const CommonLayout_ROUTES: Routes = [
    {
        path: '', 
        redirectTo: "modelo",
        pathMatch: "full"
    },
    {
        path: 'cabecalho',
        data: {
            title: 'Cabeçalho'
        },
        loadChildren: () => import('../../modules/admin/cabecalho/cabecalho.module').then(m => m.CabecalhoModule),
    },
    {
        path: 'rodape',
        data: {
            title: 'Rodapé'
        },
        loadChildren: () => import('../../modules/admin/rodape/rodape.module').then(m => m.RodapeModule),
    },
    {
        path: 'modelo',
        data: {
            title: 'Modelo'
        },
        loadChildren: () => import('../../modules/admin/modelo/modelo.module').then(m => m.ModeloModule),
    },
    {
        path: 'tipo-parametro',
        data: {
            title: 'Tipo de Parâmetro'
        },
        loadChildren: () => import('../../modules/admin/tipo-parametro/tipo-parametro.module').then(m => m.TipoParametroModule),
    },
    {
        path: 'parametro',
        data: {
            title: 'Parâmetro'
        },
        loadChildren: () => import('../../modules/admin/parametro/parametro.module').then(m => m.ParametroModule),
    },

];