<nz-modal [(nzVisible)]="showModal" nzTitle="Preencha as informações das variáveis" (nzOnCancel)="cancelar()" [nzFooter]="modalFooter">
    
    <form [formGroup]="form">
        <nz-table>
            <tr *ngFor="let item of listIdInputs">
                <td><nz-form-label nzFor="{{item}}">{{item}}</nz-form-label></td>
                <td><input nz-input [formControlName]="item"></td>
            </tr>
        </nz-table>
        <ng-template #modalFooter>
            <button nz-button nzType="default" (click)="cancelar()">Sair</button>
            <button nz-button nzType="primary" (click)="enviar()" [nzLoading]="isConfirmLoading" [disabled]="form.invalid">Gerar</button>
          </ng-template>
    </form>
</nz-modal>
