import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


@Injectable({
  providedIn: "root"
})
export class VisualizadorService{

    private apiUrl = "api/v1/documento";

    constructor(
        private http: HttpClient
    ) {}

    upload(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/uploadInterno`, formData)
        .pipe(
            take(1),
            map(anexos => anexos[0])
        );
    }

}
