import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-input-variaveis',
  templateUrl: './modal-input-variaveis.component.html',
  styleUrls: ['./modal-input-variaveis.component.css']
})
export class ModalInputVariaveisComponent implements OnChanges {

  @Input() showModal = false;
  @Input() listIdInputs: string[] = [];
  @Output() okEvent = new EventEmitter<Map<string, string>>();
  @Output() cancelEvent = new EventEmitter<Map<string, string>>();
  
  mapVariaveis: Map<string, string> = new Map<string, string>();

  form: FormGroup;

  constructor(){}

  ngOnChanges(changes: SimpleChanges): void {

    this.form = new FormGroup({})
    this.listIdInputs.forEach(variavel=>{
      this.form.addControl(variavel, new FormControl('', Validators.required));
    });
  }

  enviar(){
    this.okEvent.emit(new Map(Object.entries(this.form.value)));
    this.fecharModal();
  }

  cancelar(){
    this.cancelEvent.emit();
    this.fecharModal();
  }

  fecharModal(){
    this.mapVariaveis.clear();
    this.showModal = false;
  }

}
